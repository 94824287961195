import { toast } from "react-toastify";
import { ReactNode } from "react";

interface props {
  text: ReactNode;
}
export const errorToast = ({ text }: props) => {
  toast.error(text, {
    position: "bottom-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
