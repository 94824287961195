import React, { MouseEventHandler } from "react";
import classes from "./Button.module.scss";
import classnames from "classnames";
interface props {
  buttonText: string;
  buttonAction: () => void;
  className?: string;
  disabled?: boolean;
}
const Button: React.FC<props> = ({ buttonText, buttonAction, className }) => {
  const buttonClicked: MouseEventHandler = (event) => {
    event.preventDefault();
    buttonAction();
  };
  return (
    <button
      className={classnames(classes.button, className)}
      onClick={buttonClicked}
    >
      {buttonText}
    </button>
  );
};
export default Button;
