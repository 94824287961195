import { toast } from "react-toastify";

interface props {
  text: string;
}

export const infoToast = ({ text }: props) => {
  toast.info(text, {
    position: "bottom-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
