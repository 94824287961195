import "./App.css";
import AddTracker from "./Components/AddTracker/AddTracker";
import Auth from "./Components/Auth/Auth";
import { useSelector } from "react-redux";
import AddTrackerInfo from "./Components/AddTrackerInfo/AddTrackerInfo";
import Navbar from "./Components/UI/Navbar/Navbar";
import { authStatusSelector } from "./store/authSlice/authSelectors";
import { resultSelector } from "./store/resultSlice/resultSelectors";
import { ToastContainer } from "react-toastify";

function App() {
  const { isLoggedIn } = useSelector(authStatusSelector);
  const { haveNewResult } = useSelector(resultSelector);

  return (
    <>
      {isLoggedIn && <Navbar />}
      <main>
        <p>سامانه ثبت گروهی ردیاب</p>
        {!isLoggedIn && <Auth />}
        {isLoggedIn && !haveNewResult && <AddTracker />}
        {haveNewResult && <AddTrackerInfo />}
        <ToastContainer />
      </main>
    </>
  );
}

export default App;
