import Form from "../Form/Form";
import React, { useRef, useState } from "react";
import { api } from "../../API/api";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { authSliceActions } from "../../store/authSlice";
import { successToast } from "../../utils/toasts/successToast";
import { LoginResponseData } from "../../Types/LoginResponseData";
import errorClasses from "../UI/Error/Error.module.scss";
import { errorToast } from "../../utils/toasts/errorToast";

const Login = () => {
  const mobileInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [emptyMobile, setEmptyMobile] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);

  const login = async () => {
    const mobile = mobileInputRef.current!.value;
    const password = passwordInputRef.current!.value;
    setEmptyMobile(!mobile);
    setEmptyPassword(!password);
    if (mobile === "") {
      errorToast({ text: "لطفا شماره موبایل خود را وارد کنید" });
    }
    if (password === "") {
      errorToast({ text: "لطفا رمز خود را وارد کنید" });
    }
    if (!password || !mobile) {
      return;
    }
    try {
      dispatch(authSliceActions.loading());
      const data: LoginResponseData = await api.post(
        "https://irgogps.com/api/v1/auth/login",
        {
          mobile: mobile,
          password: password,
        },
      );
      if (data.status === 1) {
        successToast({ text: "با موفقیت وارد شدید" });
        dispatch(authSliceActions.login(data.token));
      }
    } catch (error) {}
  };
  return (
    <Form title="ورود به سامانه" buttonText="ورود" buttonAction={login}>
      <div>
        <label htmlFor="mobile">شماره موبایل</label>
        <input
          id="mobile"
          type="text"
          ref={mobileInputRef}
          className={emptyMobile ? errorClasses.error : ""}
        />
      </div>
      <div>
        <label htmlFor="password">رمز عبور</label>
        <input
          id=""
          type="password"
          ref={passwordInputRef}
          className={emptyPassword ? errorClasses.error : ""}
        />
      </div>
    </Form>
  );
};

export default Login;
