import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../../Types/AuthState";

const authDefaultState = {
  status: {
    isLoggedIn: false,
    token: "",
    isLoading: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState: authDefaultState as AuthState,
  reducers: {
    login(state, action) {
      state.status = {
        isLoggedIn: true,
        token: action.payload,
        isLoading: false,
      };
    },
    logout(state) {
      state.status = authDefaultState.status;
    },
    loading(state) {
      state.status.isLoading = true;
    },
  },
});

export default authSlice;
export const authSliceActions = authSlice.actions;
