import axios from "axios";
import { store } from "../store";
import { authSliceActions } from "../store/authSlice";
import { errorToast } from "../utils/toasts/errorToast";

const api = axios.create({});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const errorMessagesArray = error.response.data.errors;
    for (const key in errorMessagesArray) {
      const errorText = errorMessagesArray[key].message.split(".").join("");
      errorToast({ text: errorText });
    }
    if (error.response.status === 401) {
      store.dispatch(authSliceActions.logout());
    }
  },
);

export { api };
