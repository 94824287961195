import { createSlice } from "@reduxjs/toolkit";
import { ResultState } from "../../Types/ResultState";

const addTrackerInfoDefaultState = {
  result: {
    results: [
      {
        imei: "",
        success: false,
      },
    ],
    haveNewResult: false,
    isLoading: false,
  },
};

const resultSlice = createSlice({
  name: "result",
  initialState: addTrackerInfoDefaultState as ResultState,
  reducers: {
    showResult(state, action) {
      state.result = {
        results: action.payload,
        haveNewResult: true,
        isLoading: false,
      };
    },
    clearResult(state) {
      state.result = addTrackerInfoDefaultState.result;
    },
    loading(state) {
      state.result.isLoading = true;
    },
    stopLoading(state) {
      state.result.isLoading = false;
    },
  },
});

export default resultSlice;
export const resultSliceActions = resultSlice.actions;
