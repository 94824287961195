import classes from "./Navbar.module.scss";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { authSliceActions } from "../../../store/authSlice";
const Navbar = () => {
  const disptach = useDispatch();
  const logoutHandler = () => {
    disptach(authSliceActions.logout());
  };
  return (
    <nav className={classes.navbar}>
      <Button buttonText="خروج" buttonAction={logoutHandler}></Button>
    </nav>
  );
};
export default Navbar;
