import classes from "./AddTrackerInfo.module.scss";
import Button from "../UI/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { resultSliceActions } from "../../store/resultSlice";
import { EachTrackerResult } from "../../Types/ResultState";
import { resultSelector } from "../../store/resultSlice/resultSelectors";

const AddTrackerInfo = () => {
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(resultSliceActions.clearResult());
  };

  const { results: resultArray } = useSelector(resultSelector);

  return (
    <div className={classes.info}>
      <p>گزارش ثبت</p>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>وضعیت</th>
            <th>IMEI</th>
          </tr>
        </thead>
        <tbody>
          {resultArray.map((result: EachTrackerResult) => (
            <tr key={result.imei}>
              <td>{result.success ? "موفق" : "ناموفق"}</td>
              <td>{result.imei}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        buttonText="بازگشت"
        buttonAction={handleBack}
        className={classes.button}
      />
    </div>
  );
};
export default AddTrackerInfo;
