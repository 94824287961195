import Form from "../Form/Form";
import classes from "./AddTracker.module.scss";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tracker } from "../../Types/Tracker";
import { authStatusSelector } from "../../store/authSlice/authSelectors";
import { api } from "../../API/api";
import { errorToast } from "../../utils/toasts/errorToast";
import { infoToast } from "../../utils/toasts/infoToast";
import { resultSliceActions } from "../../store/resultSlice";
import { AddTrackerResponseData } from "../../Types/AddTrackerResponseData";
import errorClasses from "../UI/Error/Error.module.scss";

const AddTracker = () => {
  const [trackersData, setTrackersData] = useState<Tracker[]>([]);
  const [enteredTrackerType, setEnteredTrackerType] = useState<string>("");
  const [enteredManufacturedDate, setEnteredManufacturedDate] =
    useState<string>("");
  const enteredImeiRef = useRef<HTMLTextAreaElement>(null);
  const enteredManufacturerRef = useRef<HTMLInputElement>(null);
  const [emptyImeiArray, setEmptyImeiArray] = useState(false);
  const { token } = useSelector(authStatusSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTrackersData = async () => {
      try {
        dispatch(resultSliceActions.loading());
        const data = await api.get(
          "https://irgogps.com/api/v1/admins/tracers/types",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        );
        if (data.status === 1) {
          dispatch(resultSliceActions.stopLoading());
          setTrackersData(data.data);
        }
      } catch (e) {
        return;
      }
    };
    fetchTrackersData();
  }, [token, dispatch]);

  const handleTrackersTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setEnteredTrackerType(event.target!.value);
  };
  const handleManufacturedDateChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setEnteredManufacturedDate(event.target!.value);
  };

  const getIdByType = (trackerName: string) => {
    const filteredTracker = trackersData.filter(
      (tracker) => tracker.name === trackerName,
    );
    return filteredTracker[0]?.id === undefined ? 1 : filteredTracker[0]?.id;
  };
  const submitTrackers = async () => {
    const addedImeisArray = enteredImeiRef
      .current!.value.split("\n")
      .filter((line) => line.trim() !== "");
    if (addedImeisArray.length === 0) {
      setEmptyImeiArray(true);
      errorToast({ text: <bdi> لطفا imei را وارد کنید</bdi> });
      return;
    }
    try {
      dispatch(resultSliceActions.loading());
      const data: AddTrackerResponseData = await api.post(
        "https://irgogps.com/api/v1/admins/tracers/mass",
        {
          manufactured_at:
            enteredManufacturedDate === ""
              ? new Date().toISOString().substr(0, 10)
              : enteredManufacturedDate,
          imeis: addedImeisArray,
          manufacturer: enteredManufacturerRef.current!.value,
          type_id: getIdByType(enteredTrackerType),
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      );
      if (data.status === 1) {
        infoToast({
          text: "ردیاب های شما ثبت شد برای دیدن گزارش ثبت کمی صبر کنید",
        });
        dispatch(resultSliceActions.showResult(data.result));
      }
    } catch (error) {
      return;
    }
  };
  return (
    <Form
      title="ثبت گروهی ردیاب"
      buttonText="ثبت"
      buttonAction={submitTrackers}
      className={classes.tracker}
    >
      <div>
        <label htmlFor="type">نوع ردیاب</label>
        {trackersData.length !== 0 && (
          <select onChange={handleTrackersTypeChange}>
            {trackersData.map((tracker) => (
              <option key={tracker.id} value={tracker.name}>
                {tracker.name}
              </option>
            ))}
          </select>
        )}
      </div>
      <div>
        <label htmlFor="creator">سازنده</label>
        <input
          id="creator"
          type="text"
          defaultValue="go"
          ref={enteredManufacturerRef}
        />
      </div>
      <div>
        <label htmlFor="created-date">تاریخ ساخت</label>
        <input
          id="created-date"
          type="date"
          defaultValue={new Date().toISOString().substr(0, 10)}
          onChange={handleManufacturedDateChange}
        />
      </div>
      <div>
        <label htmlFor="imeis">imei - هر خط یک مورد</label>
        <textarea
          ref={enteredImeiRef}
          className={emptyImeiArray ? errorClasses.error : ""}
        />
      </div>
    </Form>
  );
};

export default AddTracker;
