import { combineReducers, createStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import resultSlice from "./resultSlice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  result: resultSlice.reducer,
});

const persistConfig = {
  key: "redux-persist",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persist = persistStore(store);
export { store, persist };
export type RootState = ReturnType<typeof store.getState>;
