import React from "react";
import Button from "../UI/Button/Button";
import classes from "./Form.module.scss";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { authStatusSelector } from "../../store/authSlice/authSelectors";
import Loader from "../UI/Loader/Loader";
interface props {
  title: string;
  children: React.ReactNode;
  buttonText: string;
  buttonAction: () => void;
  className?: string;
}
const Form: React.FC<props> = ({
  title,
  children,
  buttonText,
  buttonAction,
  className,
}) => {
  const { isLoading: isAuthLoading } = useSelector(authStatusSelector);
  const { isLoading: trackersDataLoading } = useSelector(authStatusSelector);
  return (
    <form className={classnames(classes.form, className)}>
      <p>{title}</p>
      {children}
      {!isAuthLoading && !trackersDataLoading && (
        <Button buttonText={buttonText} buttonAction={buttonAction} />
      )}
      {(isAuthLoading || trackersDataLoading) && <Loader />}
    </form>
  );
};
export default Form;
